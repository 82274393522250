
import PngIcons from './icons/Pngincons';
import { useNavigate } from 'react-router-dom';

const FooterComp = () => {
      const navigate=useNavigate()

  return (
    <div className='footer'>
    <div className='foootermain'>
      <img alt='' className='footerlogo' src={PngIcons.footerlogo}>
      </img>
      <p className='footerpara'>
        Our mission is to educate employees on recognizing and avoiding smishing scams and understand an organizations exposure to smishing.
      </p>
      <img alt='' className='social' src={PngIcons.social}></img>
    </div>

    <div className='links'>
      <div onClick={()=>{navigate('/')}} style={{ color: '#EF4444' }} className='linksname'>
        Home
      </div>
      <div onClick={()=>{navigate('/about')}} className='linksname'>
        About Us
      </div>
      <div onClick={()=>{navigate('/smishing')}} className='linksname'>
        Smishing 101
      </div>
      <div onClick={()=>{navigate('/joinsmishing')}} className='linksname'>
        Why Smish
      </div>
      <div onClick={()=>{navigate('/contact')}} className='linksname'>
        Contact Us
      </div>
    </div>

    <div  className='termslinks'>
          <div onClick={()=>{navigate('/termsandcondition')}} className='termslinksname'>
              Terms And Conditions
            </div>
            <div onClick={()=>{navigate('/privacypolicy')}} className='termslinksname'>
             Privacy Policy
            </div>
          </div>

    <p className='linksname'>
      © Copyright 2025 SmishTrap.
    </p>
  </div>
  );
};

export default FooterComp;
