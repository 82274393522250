import Button from 'react-bootstrap/Button';

import PngIcons from './icons/Pngincons';
import Slider from './Slider';
import NavbarComp from './Navbar';
import { useNavigate } from 'react-router-dom';
import FooterComp from './Footer';

const About = () => {
  const navigate=useNavigate()

  return (
    <div className='w-100' id='aboutcontainer' >
    <NavbarComp/>

      <div className='aboutus-firstbox'>
        <p className='aboutus-firstabout'>
          About us
        </p>
        <h1 className='aboutus-firsthead'>
          SmishTrap is Helping to Build The Human Firewall Worldwide.
          <p className='aboutus-firstpara'>
            Let's understand your risk and mitigate risk together
          </p>
        </h1>
      </div>

      <div className='Security-section-secondbox'>
        <div className='Security-section-compflex'>
          <img alt='' className='Security-section-compimg' src={PngIcons.component}></img>


          <div className='Security-section-comptext'>
            <h1>Let's Improve Security <br></br> Together
              <p>
                Red Team Analysts are frequently tasked with devising an exhaustive list of potential attack scenarios, often an impossible feat given the ever-evolving landscape of cyber threats. We at SmishTrap were recently challenged with having to create and deploy a smishing campaign.  We immediately turned to Google and were left with a pile of legal headaches, paperwork and configuration to use a technology create in 1992.
              </p>
              <h3>
                In response, we’ve developed a self-service, fully automated solution that requires no human intervention—empowering organizations to stay ahead of threats independently.
              </h3>

              <h3>
              Our mission is to educate employees on recognizing and avoiding phishing scams, comprehending various cyber risks, and implementing cybersecurity best practices. Through our product, users can build their knowledge and resilience against potential threats, helping to safeguard their organizations against malicious activities.
              </h3>
              <p className='Security-section-newsecondpara'>
          By integrating our solution, you’ll be taking a proactive step towards a more secure future. Try us today, and together, we can make the world a safer place for everyone.
        </p>

        <div className='Security-section-signsignnewsecondpara'>
          Greg Bammel
        </div>
            </h1>
          </div>
        </div>
      </div>

      {/* fifth box */}
      <div className='opportunity-section-fifthbox'>
        <div className='opportunity-section-mainbox'>
          <div className='opportunity-section-mainboxheading'>
            Don't miss this opportunity to try SmishTrap
            <p className='opportunity-section-mainboxpara'>
              Sign up for free today and immedately test SmishTrap with your own phone number.  
            </p>
            <div className='opportunity-section-fiftbtn'>
              <Button onClick={()=>{navigate('/comingsoon')}} className='opportunity-section-fifthfirstbtn'>Get Started</Button>
              <Button onClick={()=>{navigate('/contact')}} className='opportunity-section-fifthsecondbtn'>
                Contact Us</Button>
            </div>
          </div>
          <img alt='' className='opportunity-section-fifthimage' src={PngIcons.phone}></img>
          <img alt='' className='frameimg' src={PngIcons.newframe}></img>
         
        </div>
      </div>

      {/* six box */}

      <div className='review-section-sixbox'>
        <div >
          <h2 className='review-section-sixboxhead'>
            Why people like us?
          </h2>
          <p className='review-section-sixboxpara'>
            Let’s see what our customers are saying
          </p>
        </div>

        <div className='carousel'>
        <Slider/>
        </div>

      </div>
      <FooterComp/>
    </div>
  )
}


export default About;