import NavbarComp from './Navbar'
import FooterComp from './Footer';

const PrivacyPolicy = () => {

    return (
        <div className='w-100' id='privacycontainer' >
            <NavbarComp />
            <div className='privacypolicy'>
                <h1 className='mainheading'>
                    Privacy Policy
                </h1>
                <p className='mainpara'>
                    Welcome to SmishTrap. Your privacy is important to us, and this Privacy Policy explains how we collect, use, and protect your information when you use our services. By using SmishTrap, you agree to the practices outlined below.
                </p>
                <h2 className='policyheading'>1. General Information</h2>
                <ul>
                    <li className='policypara'><strong>Website Name and Domain URL:</strong> <a href="https://www.SmishTrap.com" target="_blank" rel="noopener noreferrer">SmishTrap.com</a></li>
                    <li className='policypara'><strong>Company Name:</strong> SmishTrap</li>
                    <li className='policypara'><strong>Contact Information:</strong> <a href="info@SmishTrap.com">info@SmishTrap.com</a></li>
                </ul>
                <h2 className='policyheading'>2. Data Collection</h2>
                <p className='policypara'>Types of Data Collected:</p>
                <ul>
                    <li className='policypara'><strong>Personal data: name, email, phone number</strong></li>
                    <li className='policypara'><strong>Company data: job title, organization name</strong></li>
                    <li className='policypara'><strong>Device or browsing data: IP address, cookies</strong> </li>
                </ul>

                <p className='policypara'>Methods of Collection:</p>
                <ul>
                    <li className='policypara'><strong>Directly: through forms, account registration, and user uploads</strong></li>
                    <li className='policypara'><strong>Automatically: Cookies and tracking tools</strong></li>
                </ul>
                <h2 className='policyheading'>3. Purpose of Data Collection</h2>
                <p className='policypara'>SmishTrap collects data for the following purposes:</p>
                <ul>
                    <li className='policypara'><strong>To send text messages for security awareness.
                    </strong></li>
                    <li className='policypara'><strong>To deliver campaigns designed to enhance organizational security by educating employees on recognizing and responding to smishing (SMS phishing) attempts.
                    </strong></li>
                    <li className='policypara'><strong>To raise awareness about phishing threats, improve vigilance, and reduce the risk of real-world security breaches.</strong> </li>
                </ul>
                <h2 className='policyheading'>4. Data Usage</h2>
                <p className='policypara'>How Data is Used:</p>
                <ul>
                    <li className='policypara'><strong>To send notifications or communications via text messages.
                    </strong></li>
                    <li className='policypara'><strong>To provide personalized experiences for users.
                    </strong></li>
                    <li className='policypara'><strong>To conduct internal analytics and research to improve our services.</strong> </li>
                </ul>
                <p className='policypara'>Text Message Consent: By providing their phone numbers, users consent to receiving text messages as part of our security awareness campaigns.</p>
                <h2 className='policyheading'>5. Data Sharing
                </h2>
                <p className='policypara'>SmishTrap does not share data with third parties except:</p>
                <ul>
                    <li className='policypara'><strong>When necessary to provide SmishTrap services.</strong></li>
                    <li className='policypara'><strong>When required by law.</strong></li>
                </ul>
                <h2 className='policyheading'>6. Data Retention
                </h2>
                <p className='policypara'>Retention Period:</p>
                <ul>
                    <li className='policypara'><strong>Data is retained for 1 year after an account is terminated or 2 years after no activity.</strong></li>
                </ul>
                <p className='policypara'>Deletion Requests: Users can request data deletion by contacting <a href="info@SmishTrap.com">info@SmishTrap.com</a></p>
                <h2 className='policyheading'>7. Data Protection
                </h2>
                <p className='policypara'>Security Measures: SmishTrap employs encryption, secure servers, and access controls to safeguard user data.</p>
                <p className='policypara'>Disclaimer: While we take significant measures to protect your data, no system can be 100% secure.</p>
                <h2 className='policyheading'>8. User Rights</h2>
                <p className='policypara'>Access and Control: Users have the right to:</p>
                <ul>
                    <li className='policypara'><strong>View, update, or delete their data.
                    </strong></li>
                    <li className='policypara'><strong>Revoke consent to receive text messages.
                    </strong></li>
                </ul>
                <p className='policypara'>Opt-Out Methods:</p>
                <ul>
                    <li className='policypara'><strong>Reply to any text message with “STOP” to unsubscribe.
                    </strong></li>
                    <li className='policypara'><strong>Contact us at info@SmishTrap.com to manage preferences.
                    </strong></li>
                </ul>
                <h2 className='policyheading'>
                    9. Cookies and Tracking
                </h2>
                <p className='policypara'>Cookie Use: SmishTrap uses session cookies and tracking cookies for analytics and functionality.</p>
                <p className='policypara'>Opt-Out: Users can disable cookies through their browser settings. Note that some features may not function properly without cookies.</p>
                
                <h2 className='policyheading'>
                    10. Changes to the Privacy Policy
                </h2>
                <p className='policypara'>
                    Modification Rights: SmishTrap reserves the right to update this Privacy Policy at any time with 30 days’ notice.
                </p>
                <p className='policypara'>
                    Notification: Users with active accounts will be notified of significant changes via email.</p>
                <h2 className='policyheading'>11. Text Messaging Compliance</h2>
                <p className='policypara'>No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All other categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties. </p>
                <p className='policypara'>SmishTrap complies with the Telephone Consumer Protection Act (TCPA). When checking the box to opt in to text messaging when registering a new account, you expressly consent to receive text messages for security awareness purposes.  Users may revoke their consent at any time by replying “STOP” to a text message, deleting their account, or by contacting info@SmishTrap.com</p>
                <p className='policypara'>Users may revoke their consent at any time by replying “STOP” to a text message or by contacting <a href="info@SmishTrap.com">info@SmishTrap.com</a></p>
                <h2 className='policyheading'>12. Dispute Resolution
                </h2>
                <p className='policypara'>This Privacy Policy is governed by the laws of the State of New Jersey. Any disputes arising under or in connection with this Privacy Policy shall be resolved exclusively in the courts of New Jersey.
                </p>
                <p className='policypara'>SmishTrap reserves the right to enforce this provision in the manner most favorable to the company.</p>
            </div>

            <FooterComp/>
        </div>

    )
}

export default PrivacyPolicy