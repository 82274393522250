import NavbarComp from './Navbar';
import FooterComp from './Footer';

const ComingSoon = () => {
  return (
    <div className='w-100' id='aboutcontainer'>
      <NavbarComp/>
      <div className='firstbox'>
        <p className='firstabout'>
          We're Working!
        </p>
        <h1 className='firsthead'>
          SmishTrap is Coming to a Phone Near You.
          <p className='firstpara'>
            Expected Release in Q1 2025
          </p>
        </h1>

      </div>
      <FooterComp />


    </div>
  )
}


export default ComingSoon;