
import PngIcons from './icons/Pngincons';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const Slider = () => {
   
    const carouselData = [
        {
            name: "Miki",
            paragraph: "Senior Red Team Engineer",
            review: "Building a smishing campaign took hours to get 10DLC compliance, create fake login pages, click tracking and more.  SmishTrap somplified that into minutes.",
            image: "/Avatar.png"
        },
        {
            name: "John",
            paragraph: "Security Analyst, ISAC",
            review: "One of the easiest tools I’ve ever had to to setup and deploy.  Our members are able to run campaigns on behalf of thier organizations.",
              image: "/caroselb.png"
        },
        {
            name: "Tony",
            paragraph: "Security Manager",
            review: "The reporting capabilities enable us to demonstrate value to our executive leadership team.",
              image: "/caressould.png"
        },

        {
            name: "Mark",
            paragraph: "Pellentesque habitant",
            review: "We deplyoed an entire smishing campagin in less than a week.",
            image: "/caresoule.png"
        },
        {
            name: "Olivia",
            paragraph: "Red Team Analyst",
            review: "This enables us to have concrete data regaridng susceptiablity to smishing campaigns",
            image: "/careseoulg.png"
        },
        {
            name: "James",
            paragraph: "Nullam malesuada erat ut turpis",
            review: "Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.",
            image: "/careosulh.png"
        },

        {
            name: "Sophia Davis",
            paragraph: "Cras dapibus",
            review: "Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.",
             image: "/caressould.png"
        },
        {
            name: "Ethan Martinez",
            paragraph: "Quisque rutrum",
            review: "Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue.",
             image: "/caroselb.png"
        },
    ];

    return(
    <>

      <div id='slider'>
      <Swiper
            spaceBetween={30}
            slidesPerView={1}
            pagination={{ clickable: true }}
            breakpoints={{
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 4 },
            }}
       
        >
            {carouselData.map((item, index) => (
                <SwiperSlide key={index}>
                    <div className='box'>
                        <div className='upperpart'>
                            <div className='picbox'>
                                <img style={{ width: '48px', height: '48px' }} src={item.image} alt="Profile" />
                                <div className='upperhead'>
                                    {item.name}
                                    <p className='uperpaar'>{item.paragraph}</p>
                                </div>
                            </div>
                            <img style={{ width: '24px', height: '24px' }} src={PngIcons.quote} alt="Quote" />
                        </div>
                        <div className='reviews'>{item.review}</div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>


        <Swiper
            spaceBetween={30}
            style={{ marginTop: '30px' }}
            slidesPerView={1}
            pagination={{ clickable: true }}
            breakpoints={{
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 4 },
            }}
            // modules={[Pagination, Autoplay]}
        >
            {carouselData.map((item, index) => (
                <SwiperSlide key={index}>
                    <div className='box'>
                        <div className='upperpart'>
                            <div className='picbox'>
                                <img style={{ width: '48px', height: '48px' }} src={item.image} alt="Profile" />
                                <div className='upperhead'>
                                    {item.name}
                                    <p className='uperpaar'>{item.paragraph}</p>
                                </div>
                            </div>
                            <img style={{ width: '24px', height: '24px' }} src={PngIcons.quote} alt="Quote" />
                        </div>
                        <div className='reviews'>{item.review}</div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>

      </div>

    </>
)}


export default Slider