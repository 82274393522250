import NavbarComp from './Navbar'
import FooterComp from './Footer';

const TermsAndCondition = () => {
    return (
        <div className='w-100' id='privacycontainer' >

            <NavbarComp />
            <div className='privacypolicy'>

                <h1 className='mainheading'>
                    Terms and Conditions
                </h1>

                <p className='mainpara'>Effective Date: 12/26/2025</p>
                <p className='mainpara'>
                    Welcome to SmishTrap . These Terms and Conditions (“Terms”) govern your access to and use of SmishTrap, owned and operated by SmishTrap (“Company,” “we,” or “us”). By using the Website, you agree to these Terms. If you do not agree, you must not use the Website.
                </p>
                <h2 className='policyheading'>1. General Information</h2>

                <ul>
                    <li className='policypara'><strong className='bold'> Website Name and Domain URL:</strong> <a href="https://www.SmishTrap.com" target="_blank" rel="noopener noreferrer">SmishTrap.com</a></li>
                    <li className='policypara'><strong className='bold'> Company Name:</strong> SmishTrap</li>
                    <li className='policypara'><strong className='bold'> Contact Information:</strong> <a href="info@SmishTrap.com">info@SmishTrap.com</a></li>
                </ul>
                <h2 className='policyheading'>2. Nature and Purpose</h2>
                <ul>
                    <li className='policypara'><strong className='bold'>Type of Website:</strong>Security Awareness</li>
                    <li className='policypara'><strong className='bold'>Target Audience:</strong>Fortune 500 Employees</li>
                    <li className='policypara'><strong className='bold'>Primary Activities:</strong>Facilitating security awareness through simulated text message-based phishing campaigns (“Smishing Campaigns”)</li>
                </ul>
                <h2 className='policyheading'>3. User Interactions</h2>
                <ul>
                    <li className='policypara'><strong className='bold'>User Accounts: </strong>Users may create accounts to access and manage Smishing Campaigns.</li>
                    <li className='policypara'><strong className='bold'>User Content:</strong>Users can submit phone numbers for company employees for campaign purposes. Users must ensure all submitted content complies with these Terms.</li>
                    <li className='policypara'><strong className='bold'>Payments and Transactions:</strong>The Website does not process payments</li>
                    <li className='policypara'><strong className='bold'>Communication:</strong>Users may contact us exclusively via email at  <a href="info@SmishTrap.com">info@SmishTrap.com</a></li>
                </ul>
                <h2 className='policyheading'>4. Intellectual Property</h2>
                <ul>
                    <li className='policypara'><strong className='bold'>Ownership: </strong> SmishTrap reserves the right to reuse, modify, or adapt any Smishing Campaigns submitted by users. However, SmishTrap does not claim ownership of the individual text messages themselves.</li>
                    <li className='policypara'><strong className='bold'>License Terms:</strong>Users grant SmishTrap a non-exclusive, royalty-free license to use, modify, and repurpose content submitted through the Website for the purposes of improving and delivering its services</li>
                </ul>
                <h2 className='policyheading'>5. Privacy and Data Handling</h2>
                <ul>
                    <li className='policypara'><strong className='bold'>Data Collection: </strong> SmishTrap collects names, emails, phone numbers, and basic company information, such as role and title</li>
                    <li className='policypara'><strong className='bold'>Third Parties:</strong>User data is not shared for commercial purposes. Data is shared with third parties only as necessary to perform SmishTrap services.</li>
                    <li className='policypara'><strong className='bold'>Cookies:</strong>Cookies are used for basic user tracking and analytics to enhance user experience and improve the Website’s functionality.</li>
                </ul>
                <h2 className='policyheading'>6. User Responsibilities</h2>
                <p className='policypara'>Prohibited Activities:</p>

                <ul>
                    <li className='policypara'>Users may not send malicious content or unauthorized messages via text message</li>
                    <li className='policypara'>Users must not use SmishTrap services for any purpose unrelated to security awareness Smishing Campaigns.</li>
                </ul>
                <u1>
                    <li className='policypara'><strong className='bold'>Account Security:</strong> Users are responsible for safeguarding their account credentials and ensuring unauthorized access does not occur.</li>
                </u1>
                <h2 className='policyheading'>7. Disclaimers and Liability</h2>

                <ul>
                    <li className='policypara'><strong className='bold'>Accuracy of Services: </strong>SmishTrap strives to provide accurate and reliable services. However, we do not guarantee the absolute accuracy, completeness, or reliability of the Website or its services</li>
                    <li className='policypara'><strong className='bold'>Limitation of Liability:</strong> SmishTrap is not liable for any direct, indirect, incidental, or consequential damages arising from the use of the Website or its services. Users assume full responsibility for the use of the Website and any associated risks</li>

                </ul>
                <h2 className='policyheading'>8. Governing Laws</h2>
                <p className='policypara'>These Terms are governed by the laws of the State of New Jersey, without regard to conflict of laws principles</p>

                <ul>
                    <li className='policypara'><strong className='bold'>Dispute Resolution: </strong>Any disputes arising from these Terms will be resolved exclusively in the courts of New Jersey. SmishTrap reserves the right to enforce this provision in a manner most favorable to the Company.</li>
                </ul>
                <h2 className='policyheading'>9. Changes and Updates</h2>
                <ul>
                    <li className='policypara'><strong className='bold'>Modification Rights: </strong>SmishTrap reserves the right to modify these Terms at any time without prior notice</li>
                    <li className='policypara'><strong className='bold'>Notification: </strong>Users will be notified of any significant changes via email. Continued use of the Website constitutes acceptance of the updated Terms.</li>

                </ul>
                <p style={{ marginTop: '50px' }} className='policypara'>By using SmishTrap, you acknowledge that you have read, understood, and agreed to these Terms. For questions or concerns, contact us at <a href="info@SmishTrap.com">info@SmishTrap.com</a></p>
            </div>
            <FooterComp />

        </div>
    )
}

export default TermsAndCondition