
import Button from 'react-bootstrap/Button';

import PngIcons from './icons/Pngincons';
import NavbarComp from './Navbar';
import { useNavigate } from 'react-router-dom';
import FooterComp from './Footer';


const Smishing = () => {
    const navigate=useNavigate()
  
    return (
        <div className='w-100' id='smishingcontainer' >
            <NavbarComp/>
            <div className='Smishing-firstbox'>
                <p className='Smishing-firstabout'>
                    Smishing 101
                </p>

                <h1 className='Smishing-firsthead'>
                    Let’s Start with Smishing 101
                    <p className='Smishing-firstpara'>
                        Begin your journey to understanding smishing with our Smishing 101 guide—learn the basics and how to stay protected.
                    </p>
                </h1>

            </div>

            <div className='Smishing-explain-secondbox'>
                <div className='Smishing-explain-compflex'>
                    <img alt='' className='Smishing-explain-compimg' src={PngIcons.smishingcomponent}></img>
                    <div className='Smishing-explain-comptext'>
                        <h1>
                            What is Smishing?
                            <p>
                                Smishing, a blend of "SMS" and "phishing," is a cybercrime where fraudulent text messages trick recipients into revealing sensitive information. Unlike traditional email phishing, smishing targets smartphones, exploiting the trust and immediacy of SMS. It often involves malicious links, malware, or requests for personal and financial details. The rise of smartphones has made smishing a growing threat.
                            </p>

                        </h1>

                        <Button     onClick={()=>{navigate('/comingsoon')}} className='smashingsignin' ahref="/ComingSoon">Get Started</Button>
                    </div>
                </div>
            </div>

            <div className='smishthirdbox'>
                <div className='smsihingthird'>
                    <div className='smsihingthirdhead'>
                        How Does Smishing Work?
                        <p className='smsihingthirdpara'>

                            Smishing exploits the trust people place in their mobile devices by sending fraudulent messages that appear to be from legitimate sources, like banks, government agencies, or delivery services. These messages often create a sense of urgency, prompting recipients to click a link, call a number, or share personal information. For example, attackers might pose as a bank alerting about suspicious activity or a delivery service requesting updates. They often use current events or seasonal trends, such as tax season or holiday shopping, to increase their chances of success. The goal is to deceive and manipulate recipients for the attacker’s gain.

                        </p>

                        <Button     onClick={()=>{navigate('/comingsoon')}} className='smashingsigninwork'>Get Started</Button>
                    </div>

                    <img alt='' className='simpimg' src={PngIcons.smishingimg}></img>
                </div>

            </div>

            {/* thirdbox */}


            <div className='smishing-mechansim-thirdbox'>

                <div>
                    <h1 className='smishing-mechansim-thirdheading'>
                        The Mechanics of a Smishing Attack


                        <p className='smishing-mechansim-thirdpara'>
                            Smishing attacks can be broken down into a few key steps:
                        </p>
                    </h1>

                    <div className='smishing-mechansim-mainflexdiv'>
                        <div className='smishing-mechansim-flexdiv'>
                            <div>
                                <p className='smishing-mechansim-number'>
                                    01
                                </p>
                                <h2 className='smishing-mechansim-numberhead'>
                                    Fake Message Creation
                                    <p className='smishing-mechansim-numberpara'>
                                        Attackers craft messages that mimic trusted sources, like banks or delivery services, using fake names or phone numbers.
                                    </p>
                                </h2>
                            </div>



                            <div>
                                <p className='smishing-mechansim-number'>
                                    02
                                </p>
                                <h2 className='smishing-mechansim-numberhead'>
                                    Urgent Call to Action
                                    <p className='smishing-mechansim-numberpara'>
                                        The message urges quick action, like clicking a link, sharing personal info, or calling a number, creating a sense of urgency.
                                    </p>
                                </h2>
                            </div>
                        </div>

                        <div>
                            <img alt='' src={[PngIcons.iphone]} className='smishing-mechansim-fleximg'>
                            </img>
                        </div>

                        <div className='smishing-mechansim-flexdiv'>
                            <div>
                                <p className='smishing-mechansim-number'>
                                    03
                                </p>
                                <h2 className='smishing-mechansim-numberhead'>
                                    Redirect to Malicious Sites or Numbers
                                    <p className='smishing-mechansim-numberpara'>
                                        Clicking the link leads to fake websites or malware downloads, or calling a number connects to fake customer service lines for data theft.
                                    </p>
                                </h2>
                            </div>



                            <div>
                                <p className='smishing-mechansim-number'>
                                    04
                                </p>
                                <h2 className='smishing-mechansim-numberhead'>
                                    Information Theft
                                    <p className='smishing-mechansim-numberpara'>
                                        Attackers use the stolen data to access bank accounts, commit identity theft, or sell the information on the dark web.
                                    </p>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* fourth box */}

            <div className='fourthbox'>
                <div className='fourthboxmain'>
                    <img alt='' className='simishingphone' src={PngIcons.smishingphone}></img>

                    <div className='fourthsimphead'>
                        Smishing is effective due to several factors:
                        <p>
                            1. <span style={{ fontWeight: 500 }}>Trust in SMS: </span>People often see texts as more personal and trustworthy than emails, making it easier for attackers to gain trust.  </p>
                        <p>
                            2.  <span style={{ fontWeight: 500 }}>Immediacy and Urgency:</span> Texts are quickly read and responded to, so attackers create urgent messages that prompt immediate action without verification.</p>
                        <p>
                            3. <span style={{ fontWeight: 500 }}>Lack of Awareness:</span> While people are more cautious of email phishing, fewer recognize the signs of smishing, making it a potent attack vector.</p>
                        <p>
                            4.<span style={{ fontWeight: 500 }}> Mobile Vulnerabilities:</span>  Outdated devices, smaller screens, and mobile interfaces can make it hard to spot fake links or sites.</p>

                        <p style={{ marginTop: '50px' }}>
                            <span style={{ fontWeight: 500 }}>Consequences:</span>
                            Victims of smishing may face financial loss, identity theft, and data breaches. For businesses, attacks can lead to reputational damage and a loss of customer trust.
                        </p>
                        <Button     onClick={()=>{navigate('/comingsoon')}} className='smashingsigninwork'>Get Started</Button>
                    </div>

                </div>
            </div>


            {/* fifth box */}
            <div className='opportunity-fifthbox'>
                <div className='opportunity-mainbox'>
                    <div className='opportunity-mainboxheading'>
                        Don't miss this opportunity to try SmishTrap
                        <p className='opportunity-mainboxpara'>
                        Sign up for free today and immedately test SmishTrap with your own phone number.  
                        </p>
                        <div className='opportunity-fiftbtn'>
                            <Button     onClick={()=>{navigate('/comingsoon')}} className='opportunity-fifthfirstbtn'>Get Started</Button>

                            <Button onClick={()=>{navigate('/contact')}} className='opportunity-fifthsecondbtn'>

                                Contact Us</Button>
                        </div>

                    </div>

                    {/* <img className='fifthimage' src={PngIcons.phone}></img> */}
                    <img alt='' className='opportunity-fifthimage' src={PngIcons.phone}></img>
                    <img alt='' className='frameimg' src={PngIcons.newframe}></img>
                    <div>

                    </div>
                </div>
            </div>

            {/* six box */}

            <div className='sixbox'>
                <div className='fourthboxmain'>




                    <div className='fourthsimphead'>
                        Protecting Against Smishing:
                        <p>
                            1. <span style={{ fontWeight: 500 }}> Be Skeptical: </span>Question unsolicited messages, especially those requesting personal information or urgent action. Verify the message by contacting the source directly.  </p>
                        <p>
                            2.  <span style={{ fontWeight: 500 }}>Avoid Clicking Links:</span>: Don’t click on links or download attachments from unknown texts. Visit the organization’s official website instead.</p>
                        <p>
                            3. <span style={{ fontWeight: 500 }}>Enable 2FA:</span> Use two-factor authentication for extra security.</p>
                        <p>
                            4.<span style={{ fontWeight: 500 }}> Report Smishing:</span>  Report suspicious messages to your carrier or the relevant organization.</p>

                        <p style={{ marginTop: '50px' }}>
                            <span style={{ fontWeight: 500 }}>Consequences:</span>
                            Smishing is a growing threat. Staying informed and cautious can help individuals and organizations protect against these attacks.
                        </p>
                        <Button onClick={()=>{navigate('/comingsoon')}}  className='smashingsigninwork'>Get Started</Button>
                    </div>

                    <img alt='' className='simishingphone' src={PngIcons.smsihingspam}></img>

                </div>
            </div>

            <FooterComp />
        </div>
    )
}

export default Smishing;