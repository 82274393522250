import Button from 'react-bootstrap/Button';
import PngIcons from './icons/Pngincons';
import Slider from './Slider';
import NavbarComp from './Navbar';
import FooterComp from './Footer';
import { useNavigate } from 'react-router-dom';

const Main = () => {
  const navigate=useNavigate()

  return (
    <div className='w-100' id='homecontainer' >
   <NavbarComp/>

      {/* first box */}
      <div>

        {/* page first section  */}
        <div className='firstbox'>

          <p className='phising'>
            Smishing Simulation Campaigns
          </p>
          <h1 className='phisheading'>
            Understand Your Exposure and Risk with Smishing Awareness Campaigns 
            <p className='phispara'>
              Similar to phishing awareness, smishing awareness works by understanding your exposure and educating your employees.  Let us help today! 
            </p>
          </h1>

          <div className='startedandsiginbtn'>
                <Button onClick={()=>{navigate('/comingsoon')}} className='signin'>Get Started</Button>
                <Button onClick={()=>{navigate('/smishing')}}  className='newbtn'>Smishing 101</Button>
          </div>

        </div>

        {/* second box */}
        {/* home page 1st pic  */}

        <div className='secondbox'>

          <div>
            <img alt='' className='secondimg' src={PngIcons.overview}>
            </img>
          </div>
        </div>

        {/* third box */}

        <div className='smishingattack'>
          <div>
            <h1 className='smishingattack-third-heading'>
              The Mechanics of a Smishing Attack
              <p className='smishingattack-third-para'>
                Smishing attacks can be broken down into a few key steps:
              </p>
            </h1>

            <div className='mainflexdiv'>
              <div className='flexdiv'>
                <div>
                  <p className='number'>
                    01
                  </p>
                  <h2 className='numberhead'>
                    Fake Message Creation
                    <p className='numberpara'>
                      Attackers craft messages that mimic trusted sources, like banks or delivery services, using fake names or phone numbers.
                    </p>
                  </h2>
                </div>
                <div>
                  <p className='number'>
                    02
                  </p>
                  <h2 className='numberhead'>
                    Urgent Call to Action
                    <p className='numberpara'>
                      The message urges quick action, like clicking a link, sharing personal info, or calling a number, creating a sense of urgency.
                    </p>
                  </h2>
                </div>
              </div>

              <div>
                <img alt='' src={[PngIcons.iphone]} className='fleximg'>
                </img>
              </div>

              <div className='flexdiv'>
                <div>
                  <p className='number'>
                    03
                  </p>
                  <h2 className='numberhead'>
                    Redirect to Malicious Sites or Numbers
                    <p className='numberpara'>
                      Clicking the link leads to fake websites or malware downloads, or calling a number connects to fake customer service lines for data theft.
                    </p>
                  </h2>
                </div>

                <div>
                  <p className='number'>
                    04
                  </p>
                  <h2 className='numberhead'>
                    Information Theft
                    <p className='numberpara'>
                      Attackers use the stolen data to access bank accounts, commit identity theft, or sell the information on the dark web.
                    </p>
                  </h2>
                </div>
              </div>
            </div>
          </div>

        </div>


        {/* fourth box */}

        <div className='mission-section-fourthbox'>
          <div className='mission-section-forthflex'>

            <img alt='' className='mission-section-forthimg' src={PngIcons.union}>
            </img>


            <div>
              <h1 className='mission-section-forthheader'>
                Let's Improve Security Together
              </h1>
              <p className='mission-section-forthpara'>
              Our mission is to educate employees on recognizing and avoiding smishing scams and provide organizations with a way to comprehend their exposure to phishing risk.  We've spent years as security analysts and built a tool for practitioners built by practitioners.
                <p className='mission-section-forthpara' style={{ marginTop: '10px' }}>
                  Let's understand your company's exposure to smishing and help build a plan to improve security.  Together, we can make the world a safer place for everyone.
                </p>

              </p>
              <div className='mission-section-fourthbtn'>
  
    <Button onClick={()=>{navigate('/comingsoon')}} className='signin'>Get Started</Button>
                <Button onClick={()=>{navigate('/smishing')}}  className='newbtn'>Smishing 101</Button>
              </div>
            </div>
          </div>
        </div>

        {/* fifth box */}

        <div className='awareness-section-fifthbox'>
          <div className='awareness-section-mainbox'>
            <div className='awareness-section-mainboxheading'>
              Awareness is the key to prevention
              <p className='awareness-section-mainboxpara'>
                Ensuring your employees are aware of smishing attacks is crucial to preventing them from falling victim.
              </p>
              <div className='awareness-section-fiftbtn'>
                <Button onClick={()=>{navigate('/comingsoon')}} className='awareness-section-fifthfirstbtn'>Get Started</Button>
                <Button onClick={()=>{navigate('/contact')}}  className='awareness-section-fifthsecondbtn'>Contact Us</Button>
              </div>
            </div>

            <img alt='' className='awareness-section-fifthimage' src={PngIcons.phone}></img>
            <img alt='' className='frameimg' src={PngIcons.newframe}></img>
           
          </div>
        </div>


        {/* six box */}

        <div className='rewiews-section-sixbox'>

          <div >
            <h2 className='rewiews-section-sixboxhead'>
              Why people like us?
            </h2>

            <p className='rewiews-section-sixboxpara'>
              Let’s see what our customers are saying
            </p>

          </div>

          <div className='carousel'>
         <Slider/>
          </div>
          
        </div>
        {/* footer */}
        <FooterComp />

      </div>
    </div>
  )
}

export default Main;